<!--抢单大厅-->
<template>
  <div style="height: 120vh;position: relative;">
    <div id="container"></div>
    <div style="position: absolute;top: 20px;left: 20px;z-index: 999;">
      <el-select style="width: 130px;margin-right: 20px;" v-model="sheng" placeholder="请选择省" clearable @change="change">
        <el-option
            v-for="item in city"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-select style="width: 130px;margin-right: 20px;" v-model="shi" placeholder="请选择城市" clearable v-if="sheng != ''" @change="selectshi">
        <el-option
            v-for="item in children"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-select style="width: 130px;" v-model="qu" placeholder="请选择区" clearable v-if="shi != ''" @change="selectqu">
        <el-option
            v-for="item in children2"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="quanguo">全国</el-button>
    </div>
    <div class="contentqiangdan">
      <div class="contentqiangdan1">
        <!--<div class="content_input1">-->
        <!--  <div style="display: flex;justify-content: space-around;">-->
        <!--    <div style="display: flex;align-items: center;">-->
        <!--      <img src="@/assets/images/home/yunli/didian.png" alt="" style="width: 15px;height: 19px;margin-right: 10px;">-->
        <!--      -->
        <!--    </div>-->
        <!--    &lt;!&ndash;<div>&ndash;&gt;-->
        <!--    &lt;!&ndash;  <img src="@/assets/images/home/yunli/xiala.png" alt="" style="width: 12px;height: 7px;">&ndash;&gt;-->
        <!--    &lt;!&ndash;</div>&ndash;&gt;-->
        <!--  </div>-->
        <!--</div>-->
      </div>

      <div class="qiangdandating">
        <div style="display: flex;align-items: center;justify-content: center;">
          <img src="@/assets/images/home/yunli/left.png" alt="" style="width: 60px;height: 4px;margin-right: 10px;">
          <span style="font-size: 27px;font-weight: bold;color: #a25200;">抢单大厅</span>
          <img src="@/assets/images/home/yunli/right.png" alt="" style="width: 60px;height: 4px;margin-left: 10px;">
        </div>
        <div style="margin-top: 10px;">
          <div class="qiangdandatingkuai" v-for="(item,index) in list" :key="index">
            <div class="kuai_t">
              <div style="display: flex;align-items: center;">
                <!--<div style="width: 36px;height: 36px;background: #fdf5e8;border-radius: 50%;display: flex;align-items: center;justify-content: space-evenly;">-->
                <!--  <img src="@/assets/images/home/gerenzhongxin/dadian.png" alt="" style="width: 18px;height: 14px;">-->
                <!--</div>-->
                <span style="font-size: 16px;font-weight: 500;color: #202033;margin-left: 10px;">{{item.rescue_type_name}}</span>
              </div>
              <!--<div style="display: flex;align-items: center;" v-if="item.leixing === 1">-->
              <!--  <div style="width: 36px;height: 36px;background: #E8F4FF;border-radius: 50%;display: flex;align-items: center;justify-content: space-evenly;">-->
              <!--    <img src="@/assets/images/home/gerenzhongxin/tuoche.png" alt="" style="width: 18px;height: 18px;">-->
              <!--  </div>-->
              <!--  <span style="font-size: 16px;font-weight: 500;color: #202033;margin-left: 10px;">{{item.leixingname}}</span>-->
              <!--</div>-->
              <!--<div style="display: flex;align-items: center;" v-if="item.leixing === 2">-->
              <!--  <div style="width: 36px;height: 36px;background: #FFEEEE;border-radius: 50%;display: flex;align-items: center;justify-content: space-evenly;">-->
              <!--    <img src="@/assets/images/home/gerenzhongxin/dadian.png" alt="" style="width: 18px;height: 14px;">-->
              <!--  </div>-->
              <!--  <span style="font-size: 16px;font-weight: 500;color: #202033;margin-left: 10px;">{{item.leixingname}}</span>-->
              <!--</div>-->
              <div style="display: flex;align-items: center;">
                <span style="font-size: 16px;font-weight: 500;color: #202033;">一口价</span>
                <span style="font-size: 14px;font-weight: 700;color: #ff9b05;margin-left: 10px;">￥</span>
                <span style="font-size: 24px;font-weight: 700;color: #ff9b05;">{{item.rob_price}}</span>
              </div>
            </div>
            <div class="kuai_b">
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <div>
                  <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">订单号码：</span>
                  <span style="font-size: 14px;font-weight: 500;color: #242832;">{{item.order_id}}</span>
                </div>
                <div>
                  <div style="background: #E8FDEF;padding-left: 5px;padding-right: 5px;">
                    <span style="width: 8px;height: 8px;background: #25eb61;border-radius: 50%;display: inline-block;margin-right: 10px;"></span>
                    <span style="font-size: 12px;font-weight: 500;color: #25eb61;">实时</span>
                  </div>
                </div>
              </div>

              <!--<div style="display: flex;justify-content: space-between;align-items: center;margin-top: 8px;">-->
              <!--  <div>-->
              <!--    <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">推送方式：</span>-->
              <!--    <span style="font-size: 14px;font-weight: 500;color: #242832;">{{item.tuisongfangshi}}</span>-->
              <!--  </div>-->
              <!--</div>-->
              <!--<div style="display: flex;justify-content: space-between;align-items: center;margin-top: 8px;">-->
              <!--  <div>-->
              <!--    <span style="font-size: 14px;font-weight: 400;color: #7e7f8c;">免收费项：</span>-->
              <!--    <span style="font-size: 14px;font-weight: 500;color: #242832;">{{item.mianshoufeixiang}}</span>-->
              <!--  </div>-->
              <!--</div>-->

              <div style="margin-top: 8px;padding: 10px;background: #f7f8fa;border-radius: 6px;" v-if="item.odd_even_address == 'double'">
                <div style="display: flex;align-items: center;">
                  <img src="@/assets/images/home/gerenzhongxin/qidian.png" alt="" style="width: 15px;height: 19px;">
                  <span style="font-size: 14px;font-weight: 400;color: #000000;margin-left: 10px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;">{{item.starting_point}}</span>
                </div>
                <div style="margin-top: 10px;display: flex;justify-content: space-between;">
                  <div style="display: flex;align-items: center;">
                    <img src="@/assets/images/home/gerenzhongxin/zhongdian.png" alt="" style="width: 15px;height: 19px;">
                    <span style="font-size: 14px;font-weight: 400;color: #000000;margin-left: 10px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;">{{item.destination}}</span>
                  </div>
                  <div style="margin-left: 10px;">
                    <el-button type="primary" @click="Go(item)">立即抢单</el-button>
                  </div>
                </div>
              </div>

              <div style="margin-top: 8px;padding: 10px;background: #f7f8fa;border-radius: 6px;" v-if="item.odd_even_address == 'single'">
                <div style="margin-top: 10px;display: flex;justify-content: space-between;">
                  <div style="display: flex;align-items: center;">
                    <img src="@/assets/images/home/gerenzhongxin/zhongdian.png" alt="" style="width: 15px;height: 19px;">
                    <span style="font-size: 14px;font-weight: 400;color: #000000;margin-left: 10px; overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 200px;">{{item.starting_point}}</span>
                  </div>
                  <div style="margin-left: 10px;" @click="Go(item)">
                    <el-button type="primary">立即抢单</el-button>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div style="margin-bottom: 10px;" v-if="list.length > 0">
            <el-pagination
                style="text-align: center; margin-top: 20px"
                layout="prev, pager, next"
                :total="total"
                :page-size="where.limit"
                :current-page="where.page"
                @size-change="ChangeSize"
                @current-change="currentChange"
                background>
            </el-pagination>
          </div>
          <div v-if="list.length == 0">
            <el-empty description="暂无数据"></el-empty>
          </div>

          <!--<div style="text-align: center;">-->
          <!--  <img src="@/assets/images/home/gerenzhongxin/xia.png" alt="" style="display: block;margin: auto;">-->
          <!--  <img src="@/assets/images/home/gerenzhongxin/xia.png" alt="" style="display: block;margin: auto;">-->
          <!--</div>-->
        </div>

      </div>
    </div>

    <el-dialog
      center
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div style="text-align: center;">
        <span>未开通抢单会员会员</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="ToActivateService">立即前往</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
//引入高德地图
import AMapLoader from '@amap/amap-jsapi-loader';
//引入高德地图key
import setting from '@/config/config'

//引入的接口
import {
  getcityList,
} from '@/api/yunli'
import {roblist, operation_money, Saveaccept, rob_member_open, rob} from '@/api/orderModule'


export default {
  data(){
    return{
      //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
      map:null,

      where:{
        page: 1,
        limit: 3
      },
      total:0,
      list:[
        // {
        //   leixing:0,
        //   leixingname:'搭电',
        //   yikoujia:'120',
        //   dingdanhaoma:'CO20230603157830',
        //   tuisongfangshi:'推送',
        //   mianshoufeixiang:'拖行12km',
        //   qidian:'槐荫区乐梦（LOMO）公馆',
        //   zhongdian:'山东省大剧院中心广场',
        // },
        // {
        //   leixing:1,
        //   leixingname:'拖车',
        //   yikoujia:'120',
        //   dingdanhaoma:'CO20230603157830',
        //   tuisongfangshi:'推送',
        //   mianshoufeixiang:'拖行12km',
        //   qidian:'槐荫区乐梦（LOMO）公馆',
        //   zhongdian:'山东省大剧院中心广场',
        // },
        // {
        //   leixing:2,
        //   leixingname:'换胎',
        //   yikoujia:'120',
        //   dingdanhaoma:'CO20230603157830',
        //   tuisongfangshi:'推送',
        //   mianshoufeixiang:'拖行12km',
        //   qidian:'槐荫区乐梦（LOMO）公馆',
        //   zhongdian:'山东省大剧院中心广场',
        // }
      ],

      dialogVisible: false,

      money:false,

      city:[],
      sheng:'',
      shi:'',
      qu:'',

      children:[],
      children2:[],

    }
  },

  mounted() {
    // 高德地图
    window._AMapSecurityConfig = {
      securityJsCode: setting.secretkey,
    }

    // 获取订单列表
    this.getList();
    // 获取城市
    this.getCity();
    // 获取授信额度查询
    this.getMoney();
  },

  methods: {
    //获取抢单列表
    getList(){
      roblist(this.where).then(res => {
        this.list = res.data.list;
        this.total = res.data.count;
        //DOM初始化完成进行地图初始化
        this.initMap();
      })
    },

    // 获取授信额度查询接口
    getMoney(){
      operation_money().then(res => {
        console.log(res)
        if(res.code == 200){
          this.money = res.data.money;
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },

    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //选择省
    change(e){
      console.log(e)
      console.log(this.city)
      this.city.forEach(item => {
        if(item.value == e){
          console.log(item)
          this.sheng = item.value
          this.children = item.children;
        }
      });
      this.where.level = '1'
      this.where.code = this.sheng;
      this.getList();
    },

    // 选择市
    selectshi(value){
      this.children.forEach(item => {
        if(item.value == value){
          console.log(item)
          this.shi = item.value
          this.children2 = item.children;
        }
      });
      this.where.level = '2'
      this.where.code = this.shi;
      this.getList();
    },

    // 选择区
    selectqu(value){
      console.log(value)
      this.children2.forEach(item => {
        if(item.value == value){
          console.log(item)
          this.qu = item.value
        }
      });
      this.where.level = '3'
      this.where.code = this.qu;
      this.getList();
    },

    // 点击全国
    quanguo(){
      this.where = {
        page: 1,
        limit: 3
      }
      this.sheng = '';
      this.shi = '';
      this.qu = '';
      this.getList();
    },


    // 初始化高德地图
    initMap() {
      AMapLoader.load({
        key: setting.key,// 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          // 设置地图容器id
          mapStyle: "amap://styles/whitesmoke",
          zoom: 13,
          center: [116.397428, 39.90923],//地图中心点
          resizeEnable: true
        });

        // 显示所有订单案件地的角标
        var markers = this.list;
        console.log(markers,'markers')
        // 添加一些分布不均的点到地图上,地图上添加三个点标记，作为参照
        markers.forEach(marker => {
          var marker = new AMap.Marker({
            map: this.map,
            icon: new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
              imageSize: new AMap.Size(30, 41),
            }),
            position: [marker.starting_point_coord[0], marker.starting_point_coord[1]],
            offset: new AMap.Pixel(-13, -30),
          });
        });
        // 添加点标记
        // marker.setMap(this.map);
        var center = this.map.getCenter();
        var setFitViewBtn = document.getElementById('setFitView');
        this.map.setFitView(null, false, [150, 60, 100, 60]);
        var newCenter = this.map.getCenter();

      }).catch(e => {
        console.log(e);
      })
    },

    handleClose(done) {
      this.dialogVisible = false;
    },

    // 抢单
    Go(item){
      console.log(item)
      // this.dialogVisible = true;
      operation_money().then(res => {
        console.log(res)
        if(res.code == 200){
          this.money = res.data.money;
          // 判断下是否有授信额度
          if(this.money == true){
            // 受理
            rob(item.id).then(res => {
              if(res.code === 200){
                this.$message.success(res.msg)
                this.getList();
              }else {
                this.$message.error(res.msg);
              }
            }).catch(e => {
              this.$message.error(e.msg);
            })
          }else {
            // this.$message.error('授信额度不足，请先充值');
            this.dialogVisible = true;
          }
        }else {
          this.$message.error(res.msg)
        }
      })
    },

    ToActivateService(){
      console.log(window.location.href)
      // this.$router.push('/ActivateService');
      let params = {
        return_url: window.location.href
      }
      rob_member_open(params).then(res => {
        if(res.code === 200){
          console.log(res)
          // this.$router.push({
          //   path:'/Alipay',
          //   query:{
          //     data: res.data.return
          //   }
          // })
          // window.open('/Alipay?data=' + res.data.return);
          // this.$router.push(res.data.return)
          // window.open(res.data.return);
          window.location.href = res.data.return;
          this.dialogVisible = false;
        }else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.msg);
      })
    },


  }

}
</script>


<style lang="scss" scoped>
#container{
  padding:0px;
  margin: 0px;
  width: 100%;
  //min-height: 300px;
  //max-height: 750px;
  height: 100%;
}
.contentqiangdan{
  //width: 100%;
  position: absolute;
  top: 20px;
  padding: 20px;
}
.contentqiangdan1{
  display: flex;
}
.content_input{
  display: inline-block;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 10px;
  .el-input__inner{
    border: none !important;
  }
}
.content_input1{
  width: 140px;
  display: inline-block;
  border-radius: 10px;
  background: #FFFFFF;
  padding: 10px;
  line-height: 36px;
  margin-left: 20px;
  cursor: pointer;
}

.qiangdandating{
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  background: linear-gradient(180deg,#ffeed8, #fef8eb);
  border-radius: 10px;
}
.fontcolor{
  font-size: 14px;
  font-weight: 400;
  color: #7e7f8c;
}
.fontcolor1{
  color: #FF9B05;
}

.qiangdandatingkuai{
  background: #ffffff;
  border: 1px solid #eedbc0;
  border-radius: 10px;
  margin-bottom: 10px;
}

.kuai_t{
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid #e9eaf1;
}

.kuai_b{
  padding: 20px;
}

</style>
